import React from 'react';
import {Card,  Table} from 'antd';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {RQGetExecutionEffectivenessPortletAPI} from '../../reactQuery/apis/rqPortletAPI';

const ExecutionEffectivenessPortlet = ({locationId, fromYearMonth, toYearMonth, token}) => {
  const {
    data = [],
  } = RQGetExecutionEffectivenessPortletAPI({
    token: token,
    filter: {},
    params: {locationId: locationId, fromYearMonth: fromYearMonth, toYearMonth: toYearMonth},
  });

  const cols = [
    {
      dataIndex: 'tmName',
      key: 'tmName',
      title: 'PSR/SE',        
    },
    {
      dataIndex: 'locationName',
      key: 'locationName',
      title: 'Location',
    },
    {
      dataIndex: 'abmName',
      key: 'abmName',
      title: 'ABM Name',
    },
    {
      dataIndex: 'rmName',
      key: 'rmName',
      title: 'RM Name',
    },
    {
      dataIndex: 'numVisits',
      key: 'numVisits',
      title: '# Calls',
    },
    {
      dataIndex: 'numOrders',
      key: 'numOrders',
      title: '# Orders',
    },
    {
      dataIndex: 'numOrdersWithImages',
      key: 'numOrdersWithImages',
      title: 'Orders With Images',
    },
    {
      dataIndex: 'orderValue',
      key: 'orderValue',
      title: 'Order Value',
    },
    {
      dataIndex: 'numStkEntries',
      key: 'numStkEntries',
      title: '# Stock Entries',
    },
    {
      dataIndex: 'rcpaEffectiveness',
      key: 'rcpaEffectiveness',
      title: 'RCPA Effectiveness (%)',
    },
  ];

  return (
    <Card className={'card-form'} title={'Execution Efficiency'}>
      <Table dataSource={
        data?.map (row => {
          return {...row,
            'locationName': row.location.name,
            'rcpaEffectiveness': row.rcpaEffectiveness.toFixed(2),
            'orderValue': row.orderValue.toFixed(2),
          };})
      } columns={cols} pagination={false} bordered={true} />
    </Card>
  );
};

ExecutionEffectivenessPortlet.propTypes = {
  locationId: PropTypes.any,
  fromYearMonth: PropTypes.any,
  toYearMonth: PropTypes.any,
  token: PropTypes.string,

};

const mapState = state => {
  return {};
};

export default connect(mapState)(ExecutionEffectivenessPortlet);
