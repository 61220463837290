import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {authLoader} from '../../loader';
import {
  ROUTE_ADD_CUSTOMER_REQUEST,
  ROUTE_APPROVE_ADD_CUSTOMER_REQUEST,
  ROUTE_DELETE_CUSTOMER_REQUEST,
} from '../../RoutePathConstant';

const AddCustomerRequest = lazy(() =>
  import('../../../screens/CustomerManagement')
    .then(module => ({default: module.AddCustomerRequest})));

const DeleteCustomerRequest = lazy(() =>
  import('../../../screens/CustomerManagement')
    .then(module => ({default: module.DeleteCustomerRequest})));

const ApproveCustomerRequest = lazy(() =>
  import('../../../screens/CustomerManagement')
    .then(module => ({default: module.ApproveCustomerRequest})));

const CustomerManagementNavigation = [
  {
    path:  ROUTE_ADD_CUSTOMER_REQUEST,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <AddCustomerRequest />
      </Suspense>
    ),
  },
  {
    path:  ROUTE_APPROVE_ADD_CUSTOMER_REQUEST,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ApproveCustomerRequest />
      </Suspense>
    ),
  },
  {
    path:  ROUTE_DELETE_CUSTOMER_REQUEST,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <DeleteCustomerRequest />
      </Suspense>
    ),
  },

];

export default CustomerManagementNavigation;
