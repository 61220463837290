import './MainDashboardIndex.less';
import {selectAuth} from '../../../redux/selectors';
import {connect} from 'react-redux';
import CampaignDashboardScreen from '../../Campaigns/CampaignDashboardScreen/CampaignDashboardScreen';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import localforage from 'localforage';
import {isEmpty} from 'lodash';
import {LOCAL_AUTH_STORAGE_KEY} from '../../../localStorage/constants';

const MainDashboardIndex = () => {

  const [localAuth, setLocalAuth] = useState({});

  useEffect(() => {
    localforage.getItem(LOCAL_AUTH_STORAGE_KEY).then(auth => setLocalAuth(auth));
  }, [localAuth]);
  return(
    <>
      {!isEmpty(localAuth) && localAuth?.appContext === 'cmt' && <CampaignDashboardScreen />
      }
    </>
  );
  
};

MainDashboardIndex.propTypes = {
  auth: PropTypes.any,
};

const mapState = state => {
  const auth = selectAuth(state);
  return {auth};
};

export default connect(mapState)(MainDashboardIndex);
