import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {authLoader} from '../../loader';
import {
  ROUTE_CUSTOMER_KYC_EDIT,
  ROUTE_CUSTOMER_KYC_ENROLL,
  ROUTE_CUSTOMER_KYC_SEARCH,
  ROUTE_CUSTOMER_KYC_SEARCH_VIEW,
  ROUTE_CUSTOMER_KYC_VIEW,
  ROUTE_NEW_CUSTOMER_KYC,
  ROUTE_UPDATE_CUSTOMER_APPROVAL_SEARCH,
  ROUTE_UPDATE_CUSTOMER_PAN,
} from '../../RoutePathConstant';

const CustomerKYCSearch = lazy(() =>
  import('../../../screens/Campaigns/CustomerKYC')
    .then(module => ({default: module.CustomerKYCSearch})));

const CustomerKYCEnroll = lazy(() =>
  import('../../../screens/Campaigns/CustomerKYC')
    .then(module => ({default: module.CustomerKYCEnroll})));

const CustomerKYCEdit = lazy(() =>
  import('../../../screens/Campaigns/CustomerKYC')
    .then(module => ({default: module.CustomerKYCEdit})));

const CustomerUpdatePAN =  lazy(() =>
  import('../../../screens/Campaigns/CustomerKYC')
    .then(module => ({default: module.CustomerKYCUpdatePAN})));

const CustomerKYCApprovalSearch =  lazy(() =>
  import('../../../screens/Campaigns/CustomerKYC')
    .then(module => ({default: module.CustomerKYCApprovalSearch})));

const CustomerKYCView = lazy(() =>
  import('../../../screens/Campaigns/CustomerKYC')
    .then(module => ({default: module.CustomerKYCViewDetails})));

const NewCustomerKYC = lazy(() =>
  import('../../../screens/Campaigns/CustomerKYC')
    .then(module => ({default: module.NewCustomerKYC})));

const CustomerKYCNavigation = [
  {
    path:  ROUTE_CUSTOMER_KYC_SEARCH,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CustomerKYCSearch />
      </Suspense>
    ),
  },
  {
    path:  ROUTE_CUSTOMER_KYC_ENROLL,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CustomerKYCEnroll />
      </Suspense>
    ),
  },
  {
    path:  ROUTE_CUSTOMER_KYC_EDIT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CustomerKYCEdit />
      </Suspense>
    ),
  },
  {
    path: ROUTE_UPDATE_CUSTOMER_APPROVAL_SEARCH,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CustomerKYCApprovalSearch />
      </Suspense>
    ),
  },
  {
    path: ROUTE_UPDATE_CUSTOMER_PAN,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CustomerUpdatePAN />
      </Suspense>
    ),
  },
  {
    path: ROUTE_CUSTOMER_KYC_VIEW,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CustomerKYCView />
      </Suspense>
    ),
  },
  {
    path: ROUTE_CUSTOMER_KYC_SEARCH_VIEW,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CustomerKYCView />
      </Suspense>
    ),
  },
  {
    path: ROUTE_NEW_CUSTOMER_KYC,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <NewCustomerKYC />
      </Suspense>
    ),
  },
//
];

export default CustomerKYCNavigation;
