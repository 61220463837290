// base
export const ROUTE_BASE_ROOT = '/'; // *WARN: DO NOT change this
export const ROUTE_BASE_NAME = '/'; // *WARN: DO NOT change this
export const ROUTE_WILDCARD = '*';

export const ROUTE_LOGIN = 'login';
//export const ROUTE_HOME = 'home';

// dashboard
export const ROUTE_DASHBOARD = 'dashboard';

export const ROUTE_INVOICES = 'invoices';

export const ROUTE_MARKETING_ACTIVITY_SUMMARY = 'home/reports/marketing_activity_summary_report';

export const ROUTE_MARKETING_ACTIVITY_DETAILS = 'home/reports/marketing_activity_detail_report';

export const ROUTE_RETAILER_TAGGING = 'home/reports/retailer_tagging_report';

export const ROUTE_HCP_ORDER_REPORT = 'home/reports/hcp_orders_report';

export const ROUTE_CALL_STOCK_REPORT = 'home/reports/call_stock_report';

export const ROUTE_EFFORT_REPORT = 'home/reports/effort_report';

export const ROUTE_JOINT_WORK = 'home/reports/jwf_report';

export const ROUTE_CALL_POB_REPORT = 'home/reports/call_pob_report';

export const ROUTE_MISSED_CALLS_REPORT = 'home/reports/missed_call_report';

export const ROUTE_ORDERS_REPORT = 'home/reports/pob_report';

export const ROUTE_JOINT_WORKING_DUMP = 'home/reports/jwf_dump_report';

export const ROUTE_STOCK_ORDER = 'home/adhoc/stockReport';

export const ROUTE_MARKETING_ACTIVITY_DUMP = 'home/reports/marketing_activity_dump';

export const ROUTE_PAYROLL_REPORT = 'home/reports/payroll_report';

export const ROUTE_POD_REPORT = 'home/reports/pod';

export const ROUTE_CUSTOMER_DUMP = 'home/reports/customer_dump_report';

//Campaign management
export const ROUTE_CAMPAIGN_SEARCH = 'home/marketing-admin/campaigns';

export const ROUTE_CAMPAIGN_DOC_TYPE_SEARCH = 'home/marketing-admin/campaigns-doc-types';
export const ROUTE_NEW_CAMPAIGN_DOC_TYPE = 'home/marketing-admin/campaigns-doc-types/new';

export const ROUTE_EDIT_CAMPAIGN_DOC_TYPE= 'home/marketing-admin/campaigns-doc-types/:id/edit';

export const ROUTE_CAMPAIGN_DEFINITION_SEARCH = 'home/marketing-admin/campaigns';
export const ROUTE_NEW_CAMPAIGN_DEFINITION = 'home/marketing-admin/campaigns/new';

export const ROUTE_EDIT_CAMPAIGN_DEFINITION= 'home/marketing-admin/campaigns/:id/edit';

//Customer KYC
export const ROUTE_CUSTOMER_KYC_SEARCH = 'home/campaign/customer-kyc';
export const ROUTE_CUSTOMER_KYC_ENROLL = 'home/campaign/customer-kyc/:id/enroll';

export const ROUTE_CUSTOMER_KYC_EDIT = 'home/campaign/customer-kyc/:id/edit';
export const ROUTE_CUSTOMER_KYC_VIEW = 'home/campaign/customer-kyc/:id/view';

export const ROUTE_NEW_CUSTOMER_KYC = 'home/campaign/customer-kyc/new';
export const ROUTE_UPDATE_CUSTOMER_APPROVAL_SEARCH = 'home/customer/kyc/approvals';

export const ROUTE_CUSTOMER_KYC_SEARCH_VIEW = 'home/customer/kyc/approvals/:id/view';

export const ROUTE_UPDATE_CUSTOMER_PAN = 'home/customer/kyc/approvals/:id/updatepan';

export const ROUTE_CUSTOMER_CAMPAIGN_ENROLL_SEARCH = 'home/campaign/enroll';
export const ROUTE_CUSTOMER_CAMPAIGN_ENROLL = 'home/campaign/enroll/:id/enroll';

export const ROUTE_CUSTOMER_ENROLLMENT_VIEW_DETAILS = 'home/campaign/enroll/:customerId/enroll/:campaignId/:enrollmentId/view';
export const ROUTE_CUSTOMER_ENROLLMENT_VIEW = 'home/campaign/enroll/:customerId/enroll/:campaignId';
export const ROUTE_CUSTOMER_ENROLLMENT_EDIT = 'home/campaign/enroll/:customerId/enroll/:campaignId/:enrollmentId/edit';

export const ROUTE_CAMPAIGN_ENROLLMENT_APPROVAL_SEARCH = 'home/campaign/enroll/approvals';

//location
export const ROUTE_LOCATION = 'home/admin/locations';
export const ROUTE_ADD_LOCATION = 'home/admin/locations/addlocation';
export const ROUTE_EDIT_LOCATION = 'home/admin/locations/:id/edit';

//Users

export const ROUTE_USERS = 'home/admin/users';
export const ROUTE_ADD_USER = 'home/admin/users/addusers';

export const ROUTE_EDIT_USER = 'home/admin/users/:id/edit';

//CMT Reports
export const ROUTE_REPORT_CLAIMS_MASTER = 'home/report/master';
export const ROUTE_REPORT_CLAIMS_REPORT = 'home/report/enrollments/:yearMonth/:status';

export const ROUTE_EXTEND_DATE = '/home/campaign/extend_dates';

export const ROUTE_PORTLET = 'portlet';

export const ROUTE_REVIEW_INVOICES = '/home/admin/review_invoice';

export const ROUTE_REVIEW_INVOICE = '/home/admin/review_invoice/:id/review';

export const ROUTE_DOWNLOAD_AGREEMENT_TEMPLATE = '/home/campaign/agreement-templates';

export const ROUTE_ADD_CUSTOMER_REQUEST = '/home/customer/request/add';

export const ROUTE_DELETE_CUSTOMER_REQUEST = '/home/customer/request/remove';
export const ROUTE_APPROVE_ADD_CUSTOMER_REQUEST = '/home/customer/request/approve';

export const ROUTE_MARKETING_ACTIVITY_APPROVAL = '/home/marketing-activity/approvals';

export const ROUTE_UPLOAD_LOGS = '/home/upload/logs';
