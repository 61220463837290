//Auth APIs
export const USER_PROFILE_API = {
  url: 'v1/user/find-by-username/<%= username %>',
  method: 'GET',
  auth: true,
};

export const EMPLOYEE_PROFILE_API = {
  url: 'v2/employee/current-profile/<%= userId %>',
  method: 'GET',
  auth: true,
};
