import PropTypes from 'prop-types';
import {Card} from 'antd';
import {DualAxes} from '@ant-design/plots';
import {useEffect, useState} from 'react';
import {RQGetCoveragePortletAPI} from '../../reactQuery/apis/rqPortletAPI';
import {isEmpty} from 'lodash';
import {toMonthYear} from '../../utils/DateUtils';
import {connect} from 'react-redux';

const CoveragePortlet = ({locationId, fromYearMonth, toYearMonth, token}) => {
  const [config, setConfig] = useState({});
  const {
    data:coverages = [],
  } = RQGetCoveragePortletAPI({
    token: token,
    filter: {},
    params: {locationId: locationId, fromYearMonth: fromYearMonth, toYearMonth: toYearMonth},
  });

  useEffect(() => {
    console.log(coverages);
    if (isEmpty(coverages)) {return;}

    const cfg = {
      data: [coverages.filter(c => c.type.id===0).sort((a, b) => a - b).map(d => {return {time: toMonthYear(d.yearMonth), OVERALL: Number((d.visitedCount / d.totalCount * 100).toFixed(1))};}),
        coverages.filter(c => c.type.id!==0).sort((a, b) => a - b).map(d => {return {time: toMonthYear(d.yearMonth), count:Number ((d.visitedCount / d.totalCount * 100).toFixed(1)), name: d.type.name};})],
      xField: 'time',
      yField: ['overall', 'count'],
      height:300,
      geometryOptions: [
        {
          geometry: 'column',
          columnWidthRatio: 0.4,
        },
        {
          geometry: 'line',
          seriesField: 'name',
        },
      ],
    };
    setConfig(cfg);

  }, [coverages]);
  if (isEmpty(config)) {
    return null;
  }

  return (
    <Card className={'card-form'} title={'% Coverage'}>
      <DualAxes {...config}  autoFit={true} />
    </Card>
  );
};

CoveragePortlet.propTypes = {
  locationId: PropTypes.any,
  fromYearMonth: PropTypes.any,
  toYearMonth: PropTypes.any,
  token: PropTypes.string,
};

const mapState = state => {
  return {};
};

export default connect(mapState)(CoveragePortlet);
