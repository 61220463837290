import React, {useEffect, useState} from 'react';
import {Card} from 'antd';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {toMonthYear} from '../../utils/DateUtils';
import {Column} from '@ant-design/charts';
import {each, groupBy} from '@antv/util';
import {RQGetJointVisitPortletAPI} from '../../reactQuery/apis/rqPortletAPI';
import {isEmpty} from 'lodash';

const JointVisitsPortlet = ({locationId, fromYearMonth, toYearMonth, token}) => {
  const [config, setConfig] = useState({});
  const {
    data:jointVisits = [],
  } = RQGetJointVisitPortletAPI({
    token: token,
    filter: {},
    params: {locationId: locationId, fromYearMonth: fromYearMonth, toYearMonth: toYearMonth},
  });

  useEffect(()=> {
    if (isEmpty(jointVisits)) {return;}
    const sorted = jointVisits.sort((a, b) => a.yearMonth-b.yearMonth);

    let uvData = sorted.map(d => {return {time: toMonthYear(d.yearMonth), type: 'Joint', counts: d.jointCalls};});
    uvData = uvData.concat(
      jointVisits.map(d => {return {time: toMonthYear(d.yearMonth), type: 'Individual', counts: d.totalCalls - d.jointCalls};}),
    );
    const ann = [];
    each(groupBy(uvData, 'time'), (values, k) => {
      const value = values.reduce((a, b) => a + b.counts, 0);
      ann.push({
        type: 'text',
        position: [k, value],
        content: `${value}`,
        style: {
          textAlign: 'center',
          fontSize: 14,
          fill: 'rgba(0,0,0,0.85)',
        },
        offsetY: -10,
      });
    });

    const cfg = {
      data: uvData,
      isStack: true,
      xField: 'time',
      yField: 'counts',
      seriesField: 'type',
      height:300,
      label: {
        position: 'middle',
        layout: [
          {
            type: 'interval-adjust-position',
          },
          {
            type: 'interval-hide-overlap',
          },
          {
            type: 'adjust-color',
          },
        ],
      },
      ann,
    };
    console.log(cfg);
    setConfig(cfg);
  }, [jointVisits]);
  if (isEmpty(config)) {
    return null;
  }
  return (
    <Card className={'card-form'} title={'Joint Visits'}>
      <Column {...config} />
    </Card>
  );
};

JointVisitsPortlet.propTypes = {
  locationId: PropTypes.any,
  fromYearMonth: PropTypes.any,
  toYearMonth: PropTypes.any,
  token: PropTypes.string,
};

const mapState = state => {
  return {};
};

export default connect(mapState)(JointVisitsPortlet);
