import {rqCreateSearchRequest} from './rqHttpsUtils';
import {useQuery} from '@tanstack/react-query';
import {RQ_SEARCH_CAMPAIGN_DASHBOARD_ENROLLMENTS_API} from './constants/rqCampaignDashboardAPIConstants';

export const RQGetCampaignDashboardEnrollments = (payload, options={}) => {
  return useQuery({
    queryKey: ['rq_search_campaign_dashboard_enrollments_api', payload],
    queryFn: async ({queryKey, signal}) => {
      const {filter, locationId, token} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_SEARCH_CAMPAIGN_DASHBOARD_ENROLLMENTS_API,
        null,
        token,
        {locationId: locationId},
        signal,
        filter,
      );
    },
    ...options,
  });
};
