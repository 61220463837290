import React, {Suspense} from 'react';
import {Spin} from 'antd';
import {Portlet} from '../../../screens/Portlet';
import {ROUTE_PORTLET} from '../../RoutePathConstant';
import {loginLoader} from '../../loader';

const PortletNavigation =
  {
    path:  ROUTE_PORTLET,
    loader: loginLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <Portlet />
      </Suspense>
    ),
  };

export default PortletNavigation;
