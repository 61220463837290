import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {authLoader} from '../../../loader';
import {ROUTE_CAMPAIGN_ENROLLMENT_APPROVAL_SEARCH} from '../../../RoutePathConstant';

const CampaignEnrollApproval = lazy(() =>
  import('../../../../screens/MarketingAdmin/CampaignEnrollmentApproval')
    .then(module => ({default: module.CampaignEnrollmentApproval})));

const CampaignEnrollApprovalNavigation = [
  {
    path:  ROUTE_CAMPAIGN_ENROLLMENT_APPROVAL_SEARCH,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CampaignEnrollApproval />
      </Suspense>
    ),
  },
];

export default CampaignEnrollApprovalNavigation;
