import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {authLoader} from '../../loader';
import {ROUTE_ADD_USER, ROUTE_EDIT_USER, ROUTE_USERS} from '../../RoutePathConstant';

const Users = lazy(() =>
  import('../../../screens/Users')
    .then(module => ({default: module.Users})));

const AddUser = lazy(() =>
  import('../../../screens/Users')
    .then(module => ({default: module.AddUser})));

const EditUser = lazy(() =>
  import('../../../screens/Users')
    .then(module => ({default: module.EditUser})));

const UsersNavigation = [
  {
    path: ROUTE_USERS,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <Users />
      </Suspense>
    ),
  },
  {
    path: ROUTE_ADD_USER,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <AddUser />
      </Suspense>
    ),
  },
  {
    path: ROUTE_EDIT_USER,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <EditUser />
      </Suspense>
    ),
  },
];

export default UsersNavigation;
