import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {authLoader} from '../../loader';
import {
  ROUTE_DOWNLOAD_AGREEMENT_TEMPLATE,
} from '../../RoutePathConstant';

const AgreementTemplateSearch = lazy(() =>
  import('../../../screens/Campaigns/AgreementTemplate')
    .then(module =>
      ({default: module.default.AgreementTemplate})));

//ROUTE_CUSTOMER_ENROLLMENT_EDIT

const AgreementTemplateNavigation = [
  {
    path:  ROUTE_DOWNLOAD_AGREEMENT_TEMPLATE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <AgreementTemplateSearch />
      </Suspense>
    ),
  },
];

export default AgreementTemplateNavigation;
