import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {authLoader} from '../../loader';
import {ROUTE_MARKETING_ACTIVITY_DUMP} from '../../RoutePathConstant';

const MarketingActivityDump = lazy(() =>
  import('../../../screens/MarketingActivityDump')
    .then(module => ({default: module.MarketingActivityDump})));

const MarketingActivityDumpNavigation = [
  {
    path:  ROUTE_MARKETING_ACTIVITY_DUMP,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <MarketingActivityDump />
      </Suspense>
    ),
  },
];

export default MarketingActivityDumpNavigation;
