import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {authLoader} from '../../loader';
import {ROUTE_ADD_LOCATION, ROUTE_EDIT_LOCATION, ROUTE_LOCATION} from '../../RoutePathConstant';

const Location = lazy(() =>
  import('../../../screens/Location')
    .then(module => ({default: module.Location})));

const AddLocation = lazy(() =>
  import('../../../screens/Location')
    .then(module => ({default: module.AddLocation})));

const EditLocation = lazy(() =>
  import('../../../screens/Location')
    .then(module => ({default: module.EditLocation})));

const LocationNavigation = [
  {
    path: ROUTE_LOCATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <Location />
      </Suspense>
    ),
  },
  {
    path: ROUTE_ADD_LOCATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <AddLocation />
      </Suspense>
    ),
  },
  {
    path: ROUTE_EDIT_LOCATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <EditLocation />
      </Suspense>
    ),
  },
];

export default LocationNavigation;
