import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {authLoader} from '../../../loader';
import {
  ROUTE_CAMPAIGN_DEFINITION_SEARCH, ROUTE_NEW_CAMPAIGN_DEFINITION,
} from '../../../RoutePathConstant';

const CampaignDefinition = lazy(() =>
  import('../../../../screens/MarketingAdmin/CampaignDefinition')
    .then(module => ({default: module.CampaignDefinition})));

const AddCampaignDefinition = lazy(() =>
  import('../../../../screens/MarketingAdmin/CampaignDefinition/AddCampaignDefinition')
    .then(module => ({default: module.default})));

/*const EditCampaignDocumentType = lazy(() =>
  import('../../../../screens/MarketingAdmin/CampaignDocumentType/EditCampaignDocumentType')
    .then(module => ({default: module.default})));
*/
const CampaignDefinitionNavigation = [
  {
    path:  ROUTE_CAMPAIGN_DEFINITION_SEARCH,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CampaignDefinition />
      </Suspense>
    ),
  },
  {
    path: ROUTE_NEW_CAMPAIGN_DEFINITION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <AddCampaignDefinition />
      </Suspense>
    ),
  },

  /*{
    path: ROUTE_EDIT_CAMPAIGN_DOC_TYPE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <EditCampaignDocumentType />
      </Suspense>
    ),
  },*/
];

export default CampaignDefinitionNavigation;
