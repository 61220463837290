import {rqCreateSearchRequest} from './rqHttpsUtils';
import {useQuery} from '@tanstack/react-query';
import {
  RQ_CALL_AVERAGE_PORTLET_API,
  RQ_COVERAGE_PORTLET_API, RQ_EXECUTION_EFFECTIVINESS_PORTLET_API, RQ_FIELD_DAYS_PORTLET_API,
  RQ_INVOICE_PORTLET_API, RQ_JOINT_VISIT_PORTLET_API,
} from './constants/rqPortletAPIConstants';

export const RQGetCallAveragePortletAPI = (payload, options={}) => {
  return useQuery({
    queryKey: ['rq_call_average_portlet_api', payload],
    queryFn: async ({queryKey, signal}) => {
      const {filter, params, token} = queryKey[1];
      console.log(params);
      return rqCreateSearchRequest(
        RQ_CALL_AVERAGE_PORTLET_API,
        null,
        token,
        params,
        signal,
        filter,
      );
    },
    ...options,
  });
};

export const RQGetCoveragePortletAPI = (payload, options={}) => {
  return useQuery({
    queryKey: ['rq_coverage_portlet_api', payload],
    queryFn: async ({queryKey, signal}) => {
      const {filter, params, token} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_COVERAGE_PORTLET_API,
        null,
        token,
        params,
        signal,
        filter,
      );
    },
    ...options,
  });
};

export const RQGetInvoicePortletAPI = (payload, options={}) => {
  return useQuery({
    queryKey: ['rq_invoice_portlet_api', payload],
    queryFn: async ({queryKey, signal}) => {
      const {filter, params, token} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_INVOICE_PORTLET_API,
        null,
        token,
        params,
        signal,
        filter,
      );
    },
    ...options,
  });
};

export const RQGetJointVisitPortletAPI = (payload, options={}) => {
  return useQuery({
    queryKey: ['rq_joint_visit_portlet_api', payload],
    queryFn: async ({queryKey, signal}) => {
      const {filter, params, token} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_JOINT_VISIT_PORTLET_API,
        null,
        token,
        params,
        signal,
        filter,
      );
    },
    ...options,
  });
};

export const RQGetFieldDaysPortletAPI = (payload, options={}) => {
  return useQuery({
    queryKey: ['rq_field_days_portlet_api', payload],
    queryFn: async ({queryKey, signal}) => {
      const {filter, params, token} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_FIELD_DAYS_PORTLET_API,
        null,
        token,
        params,
        signal,
        filter,
      );
    },
    ...options,
  });
};

export const RQGetExecutionEffectivenessPortletAPI = (payload, options={}) => {
  return useQuery({
    queryKey: ['rq_execution_effectiviness_portlet_api', payload],
    queryFn: async ({queryKey, signal}) => {
      const {filter, params, token} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_EXECUTION_EFFECTIVINESS_PORTLET_API,
        null,
        token,
        params,
        signal,
        filter,
      );
    },
    ...options,
  });
};
