import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {authLoader} from '../../../loader';
import {
  ROUTE_CAMPAIGN_DOC_TYPE_SEARCH, ROUTE_EDIT_CAMPAIGN_DOC_TYPE,
  ROUTE_NEW_CAMPAIGN_DOC_TYPE,
} from '../../../RoutePathConstant';

const CampaignDocumentType = lazy(() =>
  import('../../../../screens/MarketingAdmin/CampaignDocumentType')
    .then(module => ({default: module.CampaignDocumentType})));

const AddCampaignDocumentType = lazy(() =>
  import('../../../../screens/MarketingAdmin/CampaignDocumentType/AddCampaignDocumentType')
    .then(module => ({default: module.default})));

const EditCampaignDocumentType = lazy(() =>
  import('../../../../screens/MarketingAdmin/CampaignDocumentType/EditCampaignDocumentType')
    .then(module => ({default: module.default})));

const CampaignDocumentTypeNavigation = [
  {
    path:  ROUTE_CAMPAIGN_DOC_TYPE_SEARCH,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CampaignDocumentType />
      </Suspense>
    ),
  },
  {
    path: ROUTE_NEW_CAMPAIGN_DOC_TYPE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <AddCampaignDocumentType />
      </Suspense>
    ),
  },
  {
    path: ROUTE_EDIT_CAMPAIGN_DOC_TYPE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <EditCampaignDocumentType />
      </Suspense>
    ),
  },
];

export default CampaignDocumentTypeNavigation;
