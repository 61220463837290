import {createReducer} from './reducerUtils';
import {
  CHANGE_APPLICATION_CONTEXT_SUCCESSFUL,
  GET_EMPLOYEE_PROFILE_FAIL,
  GET_EMPLOYEE_PROFILE_SUCCESSFUL,
  GET_USER_PROFILE_FAIL,
  GET_USER_PROFILE_SUCCESSFUL,
  IDENTIFY_AUTH_EXIST_ON_DEVICE_SUCCESSFUL,
  LOGIN_FAIL,
  LOGIN_SUCCESSFUL,
  LOGOUT_SUCCESSFUL,
  RESET_AUTH_DATA,
  RESET_DATA,
} from '../actionConstants';
import {assign, merge} from 'lodash';

const initialState = {
  login: {
    username: '',
    error: {message: '', errorCode: ''},
  },
  token: {
    access_token: '',
  },
  loggedIn: false,
  user: {
    username: '',
  },
};

const loginSuccessful = (state = initialState, payload) => {
  console.log(payload, 'PPPP');
  return merge({}, state, {
    loggedIn: true,
    privileges: payload?.privileges,
    token: payload?.token,
    login: {error: {message: '', errorCode: ''}},
  });
};

const loginFail = (state = initialState, payload) => {
  return merge({}, state, {
    loggedIn: false,
    login: {error: {...payload}},
  });
};

const logoutSuccessful = (state = initialState, payload) => {
  return merge({}, state, {loggedIn: false});
};

const getUserProfileSuccessful = (state = initialState, payload) => {
  return assign({}, state,
    {user: {...payload}, userProfileUpdated: true});
};

const getUserProfileFail = (state = initialState, payload) => {
  return assign({}, state, {
    user: {error: {...payload}},
    userProfileUpdated: false,
  });
};

const getEmployeeProfileSuccessful = (state = initialState, payload) => {
  return assign({}, state, {
    employee: {...payload},
    employeeProfileUpdated: true,
  });
};

const getEmployeeProfileFail = (state = initialState, payload) => {
  return assign({}, state, {
    employee: {error: {...payload}},
    employeeProfileUpdated: false,
  });
};

const resetAuthData = (state = initialState) => {
  return initialState;
};

const changeApplicationContextSuccessFul = (state = initialState, payload) => {
  return assign({}, state, {
    appContext: payload,
    appContextUpdated: true,
  });
};

export default createReducer(initialState, {
  [RESET_DATA]: resetAuthData,
  [RESET_AUTH_DATA]: resetAuthData,
  [IDENTIFY_AUTH_EXIST_ON_DEVICE_SUCCESSFUL]: loginSuccessful,
  [LOGIN_SUCCESSFUL]: loginSuccessful,
  [LOGIN_FAIL]: loginFail,
  [LOGOUT_SUCCESSFUL]: logoutSuccessful,
  [GET_USER_PROFILE_SUCCESSFUL]: getUserProfileSuccessful,
  [GET_USER_PROFILE_FAIL]: getUserProfileFail,
  [GET_EMPLOYEE_PROFILE_SUCCESSFUL]: getEmployeeProfileSuccessful,
  [GET_EMPLOYEE_PROFILE_FAIL]: getEmployeeProfileFail,
  [CHANGE_APPLICATION_CONTEXT_SUCCESSFUL]: changeApplicationContextSuccessFul,
});
