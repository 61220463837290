import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {authLoader} from '../../loader';
import {ROUTE_MARKETING_ACTIVITY_SUMMARY} from '../../RoutePathConstant';

const MarketingActivitySummary = lazy(() =>
  import('../../../screens/MarketingActivitySummary')
    .then(module => ({default: module.MarketingActivitySummary})));

const MarketingActivitySummaryNavigation = [
  {
    path:  ROUTE_MARKETING_ACTIVITY_SUMMARY,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <MarketingActivitySummary />
      </Suspense>
    ),
  },
];

export default MarketingActivitySummaryNavigation;
