import React from 'react';
import {useSearchParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import CallAveragePortlet from './CallAveragePortlet';
import {toYearMonth} from '../../utils/DateUtils';
import CoveragePortlet from './CoveragePortlet';
import OrderInvoicePortlet from './OrderInvoicePortlet';
import JointVisitsPortlet from './JointVisitsPortlet';
import NoOfFieldDaysPortlet from './NoOfFieldDaysPortlet';
import ExecutionEffectivenessPortlet from './ExecutionEffectivenessPortlet';
import SalesTargetPortlet from './SalesTargetPortlet';

const PortletWrapperComponent = ({authInfo}) => {
  const [searchParams] = useSearchParams();
  console.log('=====================================', searchParams);
  console.log(searchParams.get('fromYearMonth'), searchParams.get('toYearMonth'), searchParams.get('locationId'), searchParams.get('portlet'));
  console.log('=====================================');

  if (searchParams.get('portlet')==='coverage') {
    return (
      <CoveragePortlet
        locationId={searchParams.get('locationId')}
        fromYearMonth={searchParams.get('fromYearMonth') || toYearMonth(Date())}
        toYearMonth={searchParams.get('toYearMonth')  || toYearMonth(Date())}
        refreshTime={searchParams.get('refreshTime')}
        token={searchParams.get('token')} />
    );
  }

  if (searchParams.get('portlet')==='callAverage') {
    return (
      <CallAveragePortlet locationId={searchParams.get('locationId')}
        fromYearMonth={searchParams.get('fromYearMonth') || toYearMonth(Date())}
        toYearMonth={searchParams.get('toYearMonth')  || toYearMonth(Date())}
        refreshTime={searchParams.get('refreshTime')}
        token={searchParams.get('token')}
      />
    );
  }
  if (searchParams.get('portlet')==='jointVisits') {
    return (
      <JointVisitsPortlet
        locationId={searchParams.get('locationId')}
        fromYearMonth={searchParams.get('fromYearMonth') || toYearMonth(Date())}
        toYearMonth={searchParams.get('toYearMonth')  || toYearMonth(Date())}
        refreshTime={searchParams.get('refreshTime')}
        token={searchParams.get('token')}
      />
    );
  }

  if (searchParams.get('portlet')==='orderInvoices') {
    return (
      <OrderInvoicePortlet
        locationId={searchParams.get('locationId')}
        fromYearMonth={searchParams.get('fromYearMonth') || toYearMonth(Date())}
        toYearMonth={searchParams.get('toYearMonth')  || toYearMonth(Date())}
        refreshTime={searchParams.get('refreshTime')}
        token={searchParams.get('token')}
      />
    );
  }

  if (searchParams.get('portlet')==='orderInvoices') {
    return (
      <SalesTargetPortlet
        locationId={searchParams.get('locationId')}
        fromYearMonth={searchParams.get('fromYearMonth') || toYearMonth(Date())}
        toYearMonth={searchParams.get('toYearMonth')  || toYearMonth(Date())}
        refreshTime={searchParams.get('refreshTime')}
        token={searchParams.get('token')}
      />
    );
  }

  if (searchParams.get('portlet')==='noOfFieldDays') {
    return (
      <NoOfFieldDaysPortlet
        locationId={searchParams.get('locationId')}
        fromYearMonth={searchParams.get('fromYearMonth') || toYearMonth(Date())}
        toYearMonth={searchParams.get('toYearMonth')  || toYearMonth(Date())}
        refreshTime={searchParams.get('refreshTime')}
        token={searchParams.get('token')}
      />
    );
  }

  if (searchParams.get('portlet')==='execution-efficiency') {
    return (
      <ExecutionEffectivenessPortlet
        locationId={searchParams.get('locationId')}
        fromYearMonth={searchParams.get('fromYearMonth') || toYearMonth(Date())}
        toYearMonth={searchParams.get('toYearMonth')  || toYearMonth(Date())}
        refreshTime={searchParams.get('refreshTime')}
        token={searchParams.get('token')}
      />
    );
  }
};

PortletWrapperComponent.propTypes = {
  authInfo: PropTypes.any,
};

const mapState = state => {
  return {};
};

export default connect(mapState)(PortletWrapperComponent);
