import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {authLoader} from '../../loader';
import {ROUTE_RETAILER_TAGGING} from '../../RoutePathConstant';

const RetailerTagging = lazy(() =>
  import('../../../screens/RetailerTagging')
    .then(module => ({default: module.RetailerTagging})));

const RetailerTaggingNavigation = [
  {
    path:  ROUTE_RETAILER_TAGGING,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <RetailerTagging />
      </Suspense>
    ),
  },
];

export default RetailerTaggingNavigation;
