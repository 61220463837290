import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Switch} from 'antd';

import {selectAuth} from '../../../../../redux/selectors';
import {LOCAL_AUTH_STORAGE_KEY} from '../../../../../localStorage/constants';

import {addApplicationContextToLocationStorage} from '../../../../../redux/actions';
import {useEffect, useState} from 'react';
import localforage from 'localforage';
import {isEmpty} from 'lodash';

const ContextSelector = ({setApplicationContext})=> {

  const [localAuth, setLocalAuth] = useState({});

  useEffect(() => {
    localforage.getItem(LOCAL_AUTH_STORAGE_KEY).then(auth => setLocalAuth(auth));
  }, []);

  const handleChangeContext = value => {
    setApplicationContext(value ? 'web' : 'cmt');
  };

  return (
    <>
      { !isEmpty(localAuth) &&  (
        <Switch defaultChecked={localAuth?.appContext ? localAuth?.appContext === 'web' : true}
          checkedChildren={'Sales Effort'} unCheckedChildren={'CMT'} onChange={handleChangeContext} />
      )
      }
    </>
  );
};

ContextSelector.propTypes = {
  auth: PropTypes.any,
  setApplicationContext: PropTypes.func.isRequired,
};

const actions = {
  setApplicationContext: addApplicationContextToLocationStorage,
};

const mapState = state => {
  const auth = selectAuth(state);
  return {auth};
};

export default connect(mapState, actions)(ContextSelector);
