import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {authLoader} from '../../loader';
import {ROUTE_MISSED_CALLS_REPORT} from '../../RoutePathConstant';

const MissedCalls = lazy(() =>
  import('../../../screens/MissedCalls')
    .then(module => ({default: module.MissedCalls})));

const MissedCallsNavigation = [
  {
    path:  ROUTE_MISSED_CALLS_REPORT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <MissedCalls />
      </Suspense>
    ),
  },
];

export default MissedCallsNavigation;
