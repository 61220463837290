import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {authLoader} from '../../loader';
import {ROUTE_CALL_POB_REPORT} from '../../RoutePathConstant';

const CallPOBReport = lazy(() =>
  import('../../../screens/CallPOBReport')
    .then(module => ({default: module.CallPOBReport})));

const CallPOBReportNavigation = [
  {
    path:  ROUTE_CALL_POB_REPORT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CallPOBReport />
      </Suspense>
    ),
  },
];

export default CallPOBReportNavigation;
