import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {authLoader} from '../../loader';
import {ROUTE_MARKETING_ACTIVITY_APPROVAL} from '../../RoutePathConstant';

const MarketingActivityApprovals = lazy(() =>
  import('../../../screens/MarketingActivityApprovals')
    .then(module => ({default: module.MarketingActivityApprovals})));

const MarketingActivityApprovalNavigation = [
  {
    path:  ROUTE_MARKETING_ACTIVITY_APPROVAL,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <MarketingActivityApprovals />
      </Suspense>
    ),
  },
];

export default MarketingActivityApprovalNavigation;
