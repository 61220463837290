import PropTypes from 'prop-types';
import {Card} from 'antd';
import {RQGetCallAveragePortletAPI} from '../../reactQuery/apis/rqPortletAPI';
import {useEffect, useState} from 'react';
import {toMonthYear} from '../../utils/DateUtils';
import {isEmpty} from 'lodash';
import {DualAxes} from '@ant-design/plots';

const CallAveragePortlet = ({locationId, fromYearMonth, toYearMonth, token})=> {
  const [config, setConfig] = useState({});
  const {
    data:callAverages = [],
  } = RQGetCallAveragePortletAPI({
    token: token,
    filter: {},
    params: {locationId: locationId, fromYearMonth: fromYearMonth, toYearMonth: toYearMonth},
  });

  useEffect(() => {
    console.log(callAverages);
    if (isEmpty(callAverages)) {return;}
   
    const cfg = {
      data: [callAverages.filter(c => c.type.id===0).sort((a, b) => a - b)?.map(d => {return {time: toMonthYear(d.yearMonth), overall: Number((d.callsPerDay).toFixed(1))};}),
        callAverages.filter(c => c.type.id!==0).sort((a, b) => a - b)?.map(d => {return {time: toMonthYear(d.yearMonth), count: Number((d.callsPerDay).toFixed(1)), name: d.type.name};})],
      xField: 'time',
      yField: ['overall', 'count'],
      height:300,
      geometryOptions: [
        {
          geometry: 'column',
          columnWidthRatio: 0.4,
        },
        {
          geometry: 'line',
          seriesField: 'name',
        },
      ],
    };
    setConfig(cfg);

  }, [callAverages]);
  if (isEmpty(config)) {
    return null;
  }
  return (
    <Card className={'card-form'} title={'Call Average'}>
      <DualAxes {...config}  autoFit={true} />
    </Card>
  );
};

CallAveragePortlet.propTypes = {
  locationId: PropTypes.any,
  fromYearMonth: PropTypes.any,
  toYearMonth: PropTypes.any,
  token: PropTypes.string,
};

export default CallAveragePortlet;
