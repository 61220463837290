import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {authLoader} from '../../loader';
import {ROUTE_JOINT_WORK} from '../../RoutePathConstant';

const JointWork = lazy(() =>
  import('../../../screens/JointWork')
    .then(module => ({default: module.JointWork})));

const JointWorkNavigation = [
  {
    path:  ROUTE_JOINT_WORK,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <JointWork />
      </Suspense>
    ),
  },
];

export default JointWorkNavigation;
