import {ExclamationCircleOutlined} from '@ant-design/icons';
import {Button, Modal, Tooltip} from 'antd';
import PropTypes from 'prop-types';

const ConfirmComponent = ({
  onConfirm,
  confirmMessage,
  titleMessage,
  confirmButtonType = 'primary',
  confirmButtonClassName = '',
  confirmButtonSize = 'middle',
  confirmButtonShape = 'default',
  confirmButtonBlockType = false,
  confirmButtonDisabled = false,
  confirmButtonIcon,
  confirmButtonTooltipText = '',
  confirmButtonDanger = false,
  children,
  confirmStyle= {},
  confirmId = '',
}) => {
  const [modal, contextHolder] = Modal.useModal();
  const confirm = () => {
    modal.confirm({
      title: titleMessage,
      icon: <ExclamationCircleOutlined />,
      content: confirmMessage,
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: onConfirm,
    });
  };

  return (
    <>
      <Tooltip placement={'top'} title={confirmButtonTooltipText}>
        <Button
          onClick={confirm}
          type={confirmButtonType}
          className={confirmButtonClassName}
          size={confirmButtonSize}
          shape={confirmButtonShape}
          block={confirmButtonBlockType}
          disabled={confirmButtonDisabled}
          style={confirmStyle}
          id={confirmId}
          icon={confirmButtonIcon}
          danger={confirmButtonDanger}
        >
          {children}
        </Button>
      </Tooltip>
      {contextHolder}
    </>
  );
};

ConfirmComponent.propTypes = {
  onConfirm: PropTypes.func,
  titleMessage: PropTypes.string,
  confirmMessage: PropTypes.string,
  confirmButtonType: PropTypes.string,
  confirmButtonClassName: PropTypes.string,
  confirmButtonSize: PropTypes.any,
  confirmButtonShape: PropTypes.any,
  confirmButtonBlockType: PropTypes.bool,
  confirmButtonDisabled: PropTypes.bool,
  confirmButtonIcon: PropTypes.any,
  confirmButtonTooltipText: PropTypes.string,
  confirmButtonDanger: PropTypes.bool,
  children: PropTypes.any,
  confirmStyle:PropTypes.object,
  confirmId: PropTypes.string,
};

export default ConfirmComponent;
