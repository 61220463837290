import PortletScreen from '../../screensComponents/Portlet/PortletScreen';

const Portlet = () => {
  return (
    <>
      <PortletScreen />
    </>
  );
};

export default Portlet;
