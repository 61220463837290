import {createRequest} from './httpUtils';
import {EMPLOYEE_PROFILE_API, USER_PROFILE_API} from './constants';

export const getUserProfileAPI = payload => {
  return createRequest(
    USER_PROFILE_API,
    null,
    payload?.token,
    {username: payload?.user?.username},
  );
};

export const getEmployeeProfileAPI = payload => {
  return createRequest(
    EMPLOYEE_PROFILE_API,
    null,
    payload?.token,
    {
      userId: payload?.user?.userId,
    },
  );
};
