import {catchError, debounceTime, filter, map, mergeMap, retry} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {of} from 'rxjs';
import {
  ADD_APPLICATION_CONTEXT_TO_LOCAL_STORAGE,
  ADD_AUTH_USER_PROFILE_TO_LOCAL_STORAGE,
  ADD_EMPLOYEE_PROFILE_TO_LOCAL_STORAGE,
  GET_EMPLOYEE_PROFILE_FAIL,
  GET_EMPLOYEE_PROFILE_START,
  GET_USER_PROFILE_FAIL,
  GET_USER_PROFILE_START,
  IDENTIFY_AUTH_EXIST_ON_DEVICE_START,
  IDENTIFY_AUTH_EXIST_ON_DEVICE_SUCCESSFUL,
  LOGIN_SUCCESSFUL,
  LOGOUT_START,
} from '../actionConstants';
import {
  changeApplicationContextSuccessFul,
  doNothing,
  getEmployeeProfileFail,
  getEmployeeProfileStart,
  getEmployeeProfileSuccessful,
  getUserProfileFail,
  getUserProfileStart,
  getUserProfileSuccessful,
  identifyAuthExistOnDeviceFail,
  identifyAuthExistOnDeviceSuccessful,
  loginFail,
  logoutStart,
  logoutSuccessful,
} from '../actions';
import {
  addApplicationContextToLocalAuth,
  addEmployeeProfileToLocalAuth,
  addLocalAuth,
  addUserProfileToLocalAuth,
  getLocalAuth,
} from '../../localStorage';
import {getEmployeeProfileAPI, getUserProfileAPI} from './apis';

export const loginSuccessfulEpic = action$ =>
  action$.pipe(
    filter(
      action =>
        action.type === LOGIN_SUCCESSFUL ||
                action.type === IDENTIFY_AUTH_EXIST_ON_DEVICE_SUCCESSFUL,
    ),
    mergeMap(userResponseData =>
      addLocalAuth({...userResponseData?.payload})
        .then(auth => getUserProfileStart(auth))
        .catch(logoutStart),
    ),
    catchError(error => of(loginFail(error.response))),
  );

export const identifyAuthExistOnDeviceStartEpic = action$ =>
  action$.pipe(
    ofType(IDENTIFY_AUTH_EXIST_ON_DEVICE_START),
    mergeMap(action =>
      getLocalAuth()
        .then(auth => {
          if (!auth) {
            return identifyAuthExistOnDeviceFail();
          }
          return identifyAuthExistOnDeviceSuccessful(auth);
        })
        .catch(error => identifyAuthExistOnDeviceFail()),
    ),
  );

export const getUserProfileStartEpic = action$ =>
  action$.pipe(
    ofType(GET_USER_PROFILE_START),
    mergeMap(action =>
      getUserProfileAPI(action.payload).pipe(
        retry(0),
        map(response => getUserProfileSuccessful(response.response)),
        catchError(error => of(getUserProfileFail(action.payload))),
      ),
    ),
  );

export const getUserProfileFailEpic = action$ =>
  action$.pipe(
    filter(action => action.type === GET_USER_PROFILE_FAIL),
    map(action => logoutStart(action.payload)),
  );

export const addUserProfileToLocalStorageEpic = action$ =>
  action$.pipe(
    ofType(ADD_AUTH_USER_PROFILE_TO_LOCAL_STORAGE),
    mergeMap(action =>
      addUserProfileToLocalAuth(action.payload)
        .then(doNothing)
        .catch(doNothing),
    ),
  );

export const logoutStartEpic = action$ =>
  action$.pipe(
    ofType(LOGOUT_START), debounceTime(500), map(action => logoutSuccessful(action.payload)),
  );

export const getEmployeeProfileSuccessfulEpic = action$ =>
  action$.pipe(
    ofType(GET_EMPLOYEE_PROFILE_START),
    mergeMap(action =>
      getEmployeeProfileAPI(action.payload).pipe(
        retry(1),
        map(response => getEmployeeProfileSuccessful(response.response)),
        catchError(error => of(getEmployeeProfileFail(error.response))),
      ),
    ),
  );

export const getEmployeeProfileFailEpic = action$ =>
  action$.pipe(
    filter(action => action.type === GET_EMPLOYEE_PROFILE_FAIL),
    map(logoutStart),
  );

export const addEmployeeProfileToLocalStorageEpic = action$ =>
  action$.pipe(
    ofType(ADD_EMPLOYEE_PROFILE_TO_LOCAL_STORAGE),
    mergeMap(action =>
      addEmployeeProfileToLocalAuth(action.payload)
        .then(doNothing)
        .catch(doNothing),
    ),
  );

export const userProfileSuccessfulEpic = action$ =>
  action$.pipe(
    filter(
      action =>
        action.type === LOGIN_SUCCESSFUL||
         action.type === IDENTIFY_AUTH_EXIST_ON_DEVICE_SUCCESSFUL,
    ),
    map(action => getEmployeeProfileStart(action.payload)),
  );

export const addApplicationContextToLocalStorageEpic = action$ =>
  action$.pipe(
    ofType(ADD_APPLICATION_CONTEXT_TO_LOCAL_STORAGE),
    mergeMap(action =>
      addApplicationContextToLocalAuth(action.payload)
        .then(() => changeApplicationContextSuccessFul(action.payload)),
    ),
  );
