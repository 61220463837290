import {Space, Typography} from 'antd';
import React from 'react';
import './WelcomeHeader.less';
import PropTypes from 'prop-types';
import {selectAuth} from '../../../../../redux/selectors';
import {connect} from 'react-redux';

const WelcomeHeader = ({auth}) => {
  return (
    <Space className={'welcome-header-box'}>
      <Typography className={'welcome-admin-title'}>
        {auth?.user?.fullName}
      </Typography>
    </Space>
  );
};

WelcomeHeader.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapState = state => {
  const auth = selectAuth(state);
  return {auth};
};

export default connect(mapState)(WelcomeHeader);
