import React, {useEffect, useState} from 'react';
import {useNavigation} from 'react-router-dom';
import {ContentLayout} from '../ContentLayout';
import {Layout} from 'antd';
import {HeaderLayout} from '../HeaderLayout';
import {SideMenu} from '../SideMenu';
import localforage from 'localforage';
import {isEmpty} from 'lodash';
import {LOCAL_AUTH_STORAGE_KEY} from '../../../../localStorage/constants';

const MainLayout = () => {

  const [localAuth, setLocalAuth] = useState({});

  useEffect(() => {
    localforage.getItem(LOCAL_AUTH_STORAGE_KEY).then(auth => setLocalAuth(auth));
  }, [localAuth]);
    
  const navigation = useNavigation();

  const [menuClicked, setMenuClicked] = useState(false);

  return (
    <Layout className={'main-layout-container'}>

      {!isEmpty(localAuth) && <SideMenu localAuth={localAuth} menuClicked={menuClicked} setMenuClicked={setMenuClicked} />}
      <Layout className={'main-header-and-content-container'}>
        <HeaderLayout menuClicked={setMenuClicked} />
        <ContentLayout navigation={navigation} />
      </Layout>
    </Layout>
  );
};

export default MainLayout;
