import {
  ADD_APPLICATION_CONTEXT_TO_LOCAL_STORAGE,
  ADD_AUTH_USER_PROFILE_TO_LOCAL_STORAGE, ADD_EMPLOYEE_PROFILE_TO_LOCAL_STORAGE, CHANGE_APPLICATION_CONTEXT_SUCCESSFUL,
  GET_EMPLOYEE_PROFILE_FAIL,
  GET_EMPLOYEE_PROFILE_START,
  GET_EMPLOYEE_PROFILE_SUCCESSFUL,
  GET_USER_PROFILE_FAIL,
  GET_USER_PROFILE_START,
  GET_USER_PROFILE_SUCCESSFUL,
  IDENTIFY_AUTH_EXIST_ON_DEVICE_FAIL,
  IDENTIFY_AUTH_EXIST_ON_DEVICE_START,
  IDENTIFY_AUTH_EXIST_ON_DEVICE_SUCCESSFUL,
  LOGIN_FAIL,
  LOGIN_START,
  LOGIN_SUCCESSFUL,
  LOGOUT_FAIL,
  LOGOUT_START,
  LOGOUT_SUCCESSFUL,
} from '../actionConstants';

export const loginStart = payload => async dispatch =>
  dispatch({
    type: LOGIN_START,
    payload: payload,
  });

export const loginSuccessful = payload => async dispatch =>
  dispatch({
    type: LOGIN_SUCCESSFUL,
    payload: payload,
  });
export const loginFail = payload => async dispatch =>
  dispatch({
    type: LOGIN_FAIL,
    payload: payload,
  });

export const logoutStart = payload => async dispatch => {
  dispatch({
    type: LOGOUT_START,
    payload: payload,
  });
};

export const logoutSuccessful = payload => async dispatch => {
  dispatch({
    type: LOGOUT_SUCCESSFUL,
    payload: payload,
  });
};

export const logoutFail = payload => async dispatch => {
  dispatch({
    type: LOGOUT_FAIL,
    payload: payload,
  });
};

export const getUserProfileStart = payload => async dispatch => {
  dispatch({
    type: GET_USER_PROFILE_START,
    payload: payload,
  });
};

export const getUserProfileSuccessful = payload => async dispatch => {
  dispatch({
    type: GET_USER_PROFILE_SUCCESSFUL,
    payload: payload,
  });
};

export const getUserProfileFail = payload => async dispatch => {
  dispatch({
    type: GET_USER_PROFILE_FAIL,
    payload: payload,
  });
};

export const identifyAuthExistOnDeviceStart = payload => dispatch => {
  dispatch({
    type: IDENTIFY_AUTH_EXIST_ON_DEVICE_START,
    payload: payload,
  });
};

export const identifyAuthExistOnDeviceSuccessful = payload => dispatch => {
  dispatch({
    type: IDENTIFY_AUTH_EXIST_ON_DEVICE_SUCCESSFUL,
    payload: payload,
  });
};

export const identifyAuthExistOnDeviceFail = payload => dispatch => {
  dispatch({
    type: IDENTIFY_AUTH_EXIST_ON_DEVICE_FAIL,
    payload: payload,
  });
};

export const addUserProfileToLocalStorage = payload => async dispatch => {
  dispatch({
    type: ADD_AUTH_USER_PROFILE_TO_LOCAL_STORAGE,
    payload: payload,
  });
};

export const getEmployeeProfileStart = payload => async dispatch => {
  dispatch({
    type: GET_EMPLOYEE_PROFILE_START,
    payload: payload,
  });
};

export const getEmployeeProfileSuccessful = payload => async dispatch => {
  dispatch({
    type: GET_EMPLOYEE_PROFILE_SUCCESSFUL,
    payload: payload,
  });
};

export const getEmployeeProfileFail = payload => async dispatch => {
  dispatch({
    type: GET_EMPLOYEE_PROFILE_FAIL,
    payload: payload,
  });
};

export const addEmployeeProfileToLocalStorage = payload => async dispatch => {
  dispatch({
    type: ADD_EMPLOYEE_PROFILE_TO_LOCAL_STORAGE,
    payload: payload,
  });
};

export const addApplicationContextToLocationStorage = payload => async dispatch => {
  dispatch({
    type: ADD_APPLICATION_CONTEXT_TO_LOCAL_STORAGE,
    payload: payload,
  });
};

export const changeApplicationContextSuccessFul = payload => async dispatch => {
  dispatch({
    type: CHANGE_APPLICATION_CONTEXT_SUCCESSFUL,
    payload: payload,
  });
};
