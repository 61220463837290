import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {authLoader} from '../../loader';
import {ROUTE_MARKETING_ACTIVITY_DETAILS} from '../../RoutePathConstant';

const MarketingActivityDetails = lazy(() =>
  import('../../../screens/MarketingActivityDetails')
    .then(module => ({default: module.MarketingActivityDetails})));

const MarketingActivityDetailsNavigation = [
  {
    path:  ROUTE_MARKETING_ACTIVITY_DETAILS,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <MarketingActivityDetails />
      </Suspense>
    ),
  },
];

export default MarketingActivityDetailsNavigation;
