import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {authLoader} from '../../loader';
import {ROUTE_JOINT_WORKING_DUMP} from '../../RoutePathConstant';

const JointWorkingDump = lazy(() =>
  import('../../../screens/JointWorkingDump')
    .then(module => ({default: module.JointWorkingDump})));

const JointWorkingDumpNavigation = [
  {
    path:  ROUTE_JOINT_WORKING_DUMP,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <JointWorkingDump />
      </Suspense>
    ),
  },
];

export default JointWorkingDumpNavigation;
