import PropTypes from 'prop-types';
import {Card} from 'antd';
import {useEffect, useState} from 'react';
import {toMonthYear} from '../../utils/DateUtils';
import {isEmpty} from 'lodash';
import {DualAxes} from '@ant-design/plots';
import {RQGetInvoicePortletAPI} from '../../reactQuery/apis/rqPortletAPI';

const OrderInvoicePortlet = ({locationId, fromYearMonth, toYearMonth, token})=> {
  const [config, setConfig] = useState({});
  const {
    data:orderInvoices = [],
  } = RQGetInvoicePortletAPI({
    token: token,
    filter: {},
    params: {locationId: locationId, fromYearMonth: fromYearMonth, toYearMonth: toYearMonth},
  });

  useEffect(() => {
    console.log(orderInvoices);
    if (isEmpty(orderInvoices)) {return;}
    const sorted = orderInvoices.sort((a, b) => a.yearMonth-b.yearMonth);
    let uvData = sorted.map(d => {
      return {time: toMonthYear(d.yearMonth), type: '# Orders', value: d.numOrders};
    });
    uvData.push(
      ...sorted.map(d => {
        return {time: toMonthYear(d.yearMonth), type: '# Invoices', value: d.numInvoices};
      }),
    );
    let data = sorted.map(d => {
      return {time: toMonthYear(d.yearMonth), type: 'Order Amount', amount: Number(d.orderValue.toFixed(0))};
    });
    data.push(
      ...sorted.map(d => {
        return {time: toMonthYear(d.yearMonth), type: 'Invoice Amount', amount: Number(d.invoiceValue.toFixed(0))};
      }),
    );
    const cfg = {
      data: [uvData, data],
      xField: 'time',
      yField: ['amount', 'value'],
      height:300,
      geometryOptions: [
        {
          geometry: 'column',
          isGroup: true,
          seriesField: 'type',
        },
        {
          geometry: 'line',
          seriesField: 'type',
        },
      ],
    };
    setConfig(cfg);

  }, [orderInvoices]);
  if (isEmpty(config)) {
    return null;
  }
  return (
    <Card className={'card-form'} title={'Order Invoices'}>
      <DualAxes {...config}  autoFit={true} />
    </Card>
  );
};

OrderInvoicePortlet.propTypes = {
  locationId: PropTypes.any,
  fromYearMonth: PropTypes.any,
  toYearMonth: PropTypes.any,
  token: PropTypes.string,
};

export default OrderInvoicePortlet;
