import React, {useEffect, useState} from 'react';
import {Card} from 'antd';
import {DualAxes} from '@ant-design/plots';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {toMonthYear} from '../../utils/DateUtils';
import {RQGetFieldDaysPortletAPI} from '../../reactQuery/apis/rqPortletAPI';
import {isEmpty} from 'lodash';

const SalesTargetPortlet = ({locationId, fromYearMonth, toYearMonth, token}) => {
  const [config, setConfig] = useState({});
  const {
    data:noOfFieldDays = [],
  } = RQGetFieldDaysPortletAPI({
    token: token,
    filter: {},
    params: {locationId: locationId, fromYearMonth: fromYearMonth, toYearMonth: toYearMonth},
  });

  useEffect(()=> {
    if (isEmpty(noOfFieldDays)) {return;}
    const sorted = noOfFieldDays.sort((a, b) => a.yyyyMm-b.yyyyMm);
    const uvData = sorted.map(d => {return {time: toMonthYear(d.yyyyMm), Call: Number(d.activityDuration).toFixed(1) * 1};});
    const data = sorted.map(d => {return {time: toMonthYear(d.yyyyMm), NonCall: Number(d.nonCallActivityDuration).toFixed(1) * 1};});
    const cfg = {
      data: [uvData, data],
      xField: 'time',
      yField: ['Call', 'NonCall'],
      height:300,
      geometryOptions: [
        {
          geometry: 'column',
          columnWidthRatio: 0.4,
        },
        {
          geometry: 'line',
          seriesField: 'NonCall',
        },
      ],
    };
    setConfig(cfg);
    console.log(cfg);
  }, [noOfFieldDays]);

  if (isEmpty(config)) {
    return null;
  }

  return (
    <Card className={'card-form'} title={'# Field Days'}>
      <DualAxes {...config} />
    </Card>
  );

};

SalesTargetPortlet.propTypes = {
  locationId: PropTypes.any,
  fromYearMonth: PropTypes.any,
  toYearMonth: PropTypes.any,
  token: PropTypes.string,
};

const mapState = state => {
  return {};
};

export default connect(mapState)(SalesTargetPortlet);
