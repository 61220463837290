import {LOCAL_AUTH_STORAGE_KEY} from './constants';
import localforage from 'localforage';
import {isEmpty} from 'lodash';

export const getLocalAuth = async () => {
  const auth = await localforage.getItem(LOCAL_AUTH_STORAGE_KEY);
  if (!isEmpty(auth?.user?.username)) {
    return auth;
  } else {
    return null;
  }
};

export const removeLocalAuth = async () => {
  await localforage.removeItem(LOCAL_AUTH_STORAGE_KEY);
  return true;
};

export const addLocalAuth = async auth => {
  console.log('hhhhhh', auth);
  await localforage.setItem(LOCAL_AUTH_STORAGE_KEY, auth);
  return auth;
};

export const addUserProfileToLocalAuth = async userProfile => {
  const localAuth = await localforage.getItem(LOCAL_AUTH_STORAGE_KEY);
  localAuth.user = {...userProfile};
  await localforage.setItem(
    LOCAL_AUTH_STORAGE_KEY,
    localAuth,
  );

  return userProfile;
};

export const addEmployeeProfileToLocalAuth = async employeeProfile => {
  const localAuth = await localforage.getItem(LOCAL_AUTH_STORAGE_KEY);
  localAuth.user = {...employeeProfile};
  await localforage.setItem(
    LOCAL_AUTH_STORAGE_KEY,
    localAuth,
  );

  return employeeProfile;
};

export const addApplicationContextToLocalAuth =  async applicationContext => {
  const localAuth = await localforage.getItem(LOCAL_AUTH_STORAGE_KEY);
  localAuth.appContext = applicationContext;
  await localforage.setItem(
    LOCAL_AUTH_STORAGE_KEY,
    localAuth,
  );
  return applicationContext;
};
