import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {authLoader} from '../../loader';
import {ROUTE_CUSTOMER_DUMP} from '../../RoutePathConstant';

const CustomerDump = lazy(() =>
  import('../../../screens/CustomerDump')
    .then(module => ({default: module.CustomerDump})));

const CustomerDumpNavigation = [
  {
    path:  ROUTE_CUSTOMER_DUMP,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CustomerDump />
      </Suspense>
    ),
  },
];

export default CustomerDumpNavigation;
