import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {authLoader} from '../../loader';
import {ROUTE_STOCK_ORDER} from '../../RoutePathConstant';

const StockOrder = lazy(() =>
  import('../../../screens/StockOrder')
    .then(module => ({default: module.StockOrder})));

const StockOrderNavigation = [
  {
    path:  ROUTE_STOCK_ORDER,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <StockOrder />
      </Suspense>
    ),
  },
];

export default StockOrderNavigation;
