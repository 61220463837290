import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_INVOICES} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const Invoices = lazy(() =>
  import('../../../screens/Invoices')
    .then(module => ({default: module.Invoices})));

const InvoiceNavigation = [
  {
    path: ROUTE_INVOICES,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <Invoices />
      </Suspense>
    ),
  },
];

export default InvoiceNavigation;
