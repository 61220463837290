export const RQ_USER_LOGIN_API = {
  url: 'v1/user/login',
  method: 'POST',
  auth: false,
};

export const RQ_USER_SEARCH_API = {
  url: 'v2/security-user/search?',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_USER_DEACTIVATE_API = {
  url: 'v2/security-user/deactivate/<%= id %>',
  method: 'PUT',
  auth: true,
};

export const RQ_USER_FOR_EDIT_API = {
  url: 'v2/employee/profile/<%= id %>',
  method: 'GET',
  auth: true,
};

export const RQ_SAVE_USER_API = {
  url: 'v2/employee/new-user',
  method: 'POST',
  auth: true,
};
