import {createBrowserRouter} from 'react-router-dom';
import {MainHome} from '../screensComponents/HomeScreen/MainHome';
import {ErrorPage} from '../components/common/ErrorPage';
import {IndexNavigation} from './screensNavigation/Index/index';
import React from 'react';
import {PublicRoute} from './PublicRoute';
import {LoginNavigation} from './screensNavigation/Login';
import {DashboardIndexNavigation} from './screensNavigation/DashboardIndex';
import {ROUTE_BASE_NAME} from './RoutePathConstant';
import {MarketingActivitySummaryNavigation} from './screensNavigation/MarketingActivitySummary';
import {InvoiceNavigation} from './screensNavigation/InvoiceNavigation';
import {MarketingActivityDetailsNavigation} from './screensNavigation/MarketingActivityDetails';
import {RetailerTaggingNavigation} from './screensNavigation/RetailerTagging';
import {HCPOrderReportNavigation} from './screensNavigation/HCPOrderReport';
import {CallStockReportNavigation} from './screensNavigation/CallStockReport';
import {EffortReportNavigation} from './screensNavigation/EffortReport';
import {JointWorkNavigation} from './screensNavigation/JointWork';
import {CallPOBReportNavigation} from './screensNavigation/CallPOBReport';
import {MissedCallsNavigation} from './screensNavigation/MissedCalls';
import OrdersReportNavigation from './screensNavigation/OrdersReport/OrdersReportNavigation';
import {JointWorkingDumpNavigation} from './screensNavigation/JointWorkingDump';
import {StockOrderNavigation} from './screensNavigation/StockOrder';
import {MarketingActivityDumpNavigation} from './screensNavigation/MarketingActivityDump';
import {PayrollReportNavigation} from './screensNavigation/PayrollReport';
import {PODReportNavigation} from './screensNavigation/PODReport';
import CustomerDumpNavigation from './screensNavigation/CustomerDump/CustomerDumpNavigation';
import CampaignDocumentTypeNavigation
  from './screensNavigation/MarketingAdmin/CampaignDocumentType/CampaignDocumentTypeNavigation';
import {CustomerKYCNavigation} from './screensNavigation/CustomerKYC';
import CustomerCampaignEnrollNavigation
  from './screensNavigation/CustomerCampaignEnrollment/CustomerCampaignEnrollNavigation';
import CampaignEnrollApprovalNavigation
  from './screensNavigation/MarketingAdmin/CampaignEnrollApproval/CampaignEnrollApprovalNavigation';
import {LocationNavigation} from './screensNavigation/Location';
import CampaignDefinitionNavigation
  from './screensNavigation/MarketingAdmin/CampaignManagement/CampaignDefinitionNavigation';
import {UsersNavigation} from './screensNavigation/Users';
import PortletNavigation from './screensNavigation/PortletNavigation/PortletNavigation';
import {ReviewInvoiceNavigation} from './screensNavigation/ReviewInvoice';
import AgreementTemplateNavigation from './screensNavigation/AgreementTemplateNavigation/AgreementTemplateNavigation';
import {CustomerManagementNavigation} from './screensNavigation/CustomerManagment';
import MarketingActivityApprovalNavigation
  from './screensNavigation/MarketingActivityApproval/MarketingActivityApprovalNavigation';
import {UploadLogsNavigation} from './screensNavigation/Uploads';

const AuthRoute = [
  ...InvoiceNavigation,
  ...MarketingActivitySummaryNavigation,
  ...MarketingActivityDetailsNavigation,
  ...RetailerTaggingNavigation,
  ...HCPOrderReportNavigation,
  ...CallStockReportNavigation,
  ...EffortReportNavigation,
  ...JointWorkNavigation,
  ...CallPOBReportNavigation,
  ...MissedCallsNavigation,
  ...OrdersReportNavigation,
  ...JointWorkingDumpNavigation,
  ...StockOrderNavigation,
  ...MarketingActivityDumpNavigation,
  ...PayrollReportNavigation,
  ...PODReportNavigation,
  ...CustomerDumpNavigation,
  ...CampaignDocumentTypeNavigation,
  ...CampaignDefinitionNavigation,
  ...CustomerKYCNavigation,
  ...CustomerCampaignEnrollNavigation,
  ...CampaignEnrollApprovalNavigation,
  ...LocationNavigation,
  ...UsersNavigation,
  ...ReviewInvoiceNavigation,
  ...AgreementTemplateNavigation,
  ...CustomerManagementNavigation,
  ...MarketingActivityApprovalNavigation,
  ...UploadLogsNavigation,
];

export const AppRouter = createBrowserRouter([
  IndexNavigation,
  LoginNavigation,
  PortletNavigation,
  {
    path: '',
    element: <MainHome />,
    errorElement: <ErrorPage />,
    children: [
      DashboardIndexNavigation,
      ...AuthRoute,
      ...PublicRoute,
    ],
  },
], {
  basename: ROUTE_BASE_NAME,
});
