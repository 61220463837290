import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {authLoader} from '../../loader';
import {
  ROUTE_CUSTOMER_CAMPAIGN_ENROLL,
  ROUTE_CUSTOMER_CAMPAIGN_ENROLL_SEARCH, ROUTE_CUSTOMER_ENROLLMENT_EDIT,
  ROUTE_CUSTOMER_ENROLLMENT_VIEW,
  ROUTE_CUSTOMER_ENROLLMENT_VIEW_DETAILS, ROUTE_EXTEND_DATE,
  ROUTE_REPORT_CLAIMS_MASTER, ROUTE_REPORT_CLAIMS_REPORT,
} from '../../RoutePathConstant';

const CustomerCampaignEnrollSearch = lazy(() =>
  import('../../../screens/Campaigns/CustomerCampaignEnrollment')
    .then(module =>
      ({default: module.CustomerCampaignEnrollmentSearch})));

const CampaignListForEnrollment = lazy(() =>
  import('../../../screens/Campaigns/CustomerCampaignEnrollment')
    .then(module =>
      ({default: module.CampaignListForEnrollment})));

const CampaignEnrollmentDetails = lazy(() =>
  import('../../../screens/Campaigns/CustomerCampaignEnrollment')
    .then(module =>
      ({default: module.CampaignEnrollmentDetails})));

const CampaignEnrollmentView = lazy(() =>
  import('../../../screens/Campaigns/CustomerCampaignEnrollment')
    .then(module =>
      ({default: module.CampaignEnrollmentView})));

const ClaimReportsSearch =lazy(() =>
  import('../../../screens/Campaigns/Reports')
    .then(module =>
      ({default: module.ClaimReportsSearch})));

const CampaignEnrollmentEdit = lazy(() =>
  import('../../../screens/Campaigns/CustomerCampaignEnrollment')
    .then(module =>
      ({default: module.CampaignEnrollmentDetailsEdit})));

const CampaignEnrollmentsReport = lazy(() =>
  import('../../../screens/Campaigns/Reports')
    .then(module =>
      ({default: module.ClaimEnrollmentsReports})));

const ExtendDate = lazy(() =>
  import('../../../screens/Campaigns/ExtendDate')
    .then(module => ({default: module.ExtendDate})));

//ROUTE_CUSTOMER_ENROLLMENT_EDIT

const CustomerCampaignEnrollNavigation = [
  {
    path:  ROUTE_CUSTOMER_CAMPAIGN_ENROLL_SEARCH,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CustomerCampaignEnrollSearch />
      </Suspense>
    ),
  },
  {
    path:  ROUTE_CUSTOMER_CAMPAIGN_ENROLL,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CampaignListForEnrollment />
      </Suspense>
    ),
  },
  {
    path:  ROUTE_CUSTOMER_ENROLLMENT_VIEW,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CampaignEnrollmentDetails />
      </Suspense>
    ),
  },
  {
    path:  ROUTE_CUSTOMER_ENROLLMENT_VIEW_DETAILS,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CampaignEnrollmentView />
      </Suspense>
    ),
  },
  {
    path:  ROUTE_REPORT_CLAIMS_MASTER,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ClaimReportsSearch />
      </Suspense>
    ),
  },
  {
    path:  ROUTE_CUSTOMER_ENROLLMENT_EDIT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CampaignEnrollmentEdit />
      </Suspense>
    ),
  },
  {
    path: ROUTE_EXTEND_DATE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ExtendDate />
      </Suspense>
    ),
  },
  {
    path: ROUTE_REPORT_CLAIMS_REPORT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CampaignEnrollmentsReport />
      </Suspense>
    ),
  },

];

export default CustomerCampaignEnrollNavigation;
