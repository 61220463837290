/* eslint-disable */
import {useEffect, useState} from 'react';
import {MenuBuilder} from './MenuBuilder';
import {Col, Drawer, Image, Layout, Menu} from 'antd';
import * as PropTypes from 'prop-types';
import './SideMenu.less';
import {Link, useLocation} from 'react-router-dom';
import {ROUTE_BASE_ROOT, ROUTE_DASHBOARD} from '../../../../navigations/RoutePathConstant';
import {selectAuth} from '../../../../redux/selectors';
import {connect} from 'react-redux';
import {RQGetSideMenusData} from '../../../../reactQuery/apis/rqUIAPI';
import {MenuFoldOutlined} from '@ant-design/icons';
import AppLogo from '../../../../assets/images/logo/app-logo.png';
import {getWindowDimensions} from '../../../../utils/CommonUtils';

const {Sider} = Layout;

const SideMenu = ({localAuth, menuClicked, setMenuClicked}) => {
  const [dimensions] = useState(getWindowDimensions());
  const [mobileView] = useState(dimensions.width < 768);
  const [collapsed, setCollapsed] = useState(false);
  const {data = []} = RQGetSideMenusData({
    token: localAuth?.token,
    userId: localAuth?.user?.userId,
    filter: {uiInterface: localAuth?.appContext || 'WEB'},
  },
  {
    enabled: !!localAuth?.user?.userId,
  });

  const location = useLocation();

  const [currentPath, setCurrentPath] = useState(
    location.pathname === ROUTE_BASE_ROOT || location.pathname === ''
      ? ROUTE_BASE_ROOT
      : location.pathname.split(ROUTE_BASE_ROOT)[1],
  );
  const onClick = () => {
    console.log('ffdfdfdfdffdf', collapsed);
    setCollapsed(!collapsed);
  };
  useEffect(() => {
    if (location) {
      if (currentPath !== location.pathname) {
        setCurrentPath(location.pathname);
      }
    }
  }, [location, currentPath]);

  if (mobileView) {
    return (
      <>
        <Drawer
          placement={'left'}
          closable={false}
          visible={menuClicked}
          onClose={() => {
            setMenuClicked(false);
          }}
        >
          <Menu
            className={'menu'}
            mode={'inline'}
            defaultSelectedKeys={[currentPath]}
            items={MenuBuilder(data)}
          />
        </Drawer>
      </>
    );
  } else {
    return (
      <Sider
        collapsible={true}
        className={`main-side-menu-container ${collapsed ? 'side-menu-collapsed' : ''}`}
        theme={'light'}
        collapsed={collapsed}
        trigger={null}
      >
        <MenuFoldOutlined className={`side-menu-show-hide-btn${collapsed ? '-collapsed' : ''}`} onClick={onClick} />
        {!collapsed && (
          <>
            <Link to={ROUTE_DASHBOARD}>
              <Col className={'side-menu-logo'}>
                <Image
                  preview={false}
                  width={'80%'}
                  src={AppLogo}
                />
              </Col>
            </Link>
            <Menu
              className={'menu'}
              mode={'inline'}
              defaultSelectedKeys={[currentPath]}
              items={MenuBuilder(data)}
            />
          </>
        )
        }
      </Sider>
    );
  }
};

SideMenu.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.any.isRequired,
    token: PropTypes.string,
    appContext: PropTypes.string,
  }),
  menuClicked: PropTypes.bool,
  onClick: PropTypes.func,
  setMenuClicked: PropTypes.func,
};

const mapState = state => {
  const auth = selectAuth(state);
  return {auth};
};

export default connect(mapState)(SideMenu);
