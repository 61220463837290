import React from 'react';
import {NavLink} from 'react-router-dom';
import {Typography} from 'antd';
import {ROUTE_DASHBOARD} from '../../../../../navigations/RoutePathConstant';

const MenuBuilder = menus => {
  let listOfMenus = [
    {
      key: 'dashboard',
      label: (
        <NavLink
          to={ROUTE_DASHBOARD}
          className={({isActive, isPending}) =>
            isActive ? 'active' : isPending ? 'pending' : ''
          }
        >
            Dashboard
        </NavLink>
      ),
    },
  ];
  menus.forEach(menu => {
    if (menu.children.length > 0) {
      listOfMenus.push(convertToMenu(menu));
      menu.children.forEach(child => listOfMenus.push(convertToMenu(child)));
    } else {
      listOfMenus.push(convertToMenu(menu));
    }
  });
  return listOfMenus;
};

const convertToMenu = menu => {
  return {
    key: menu.key,
    label: (
      <NavLink
        to={`${menu.path}`}
        className={({isActive, isPending}) =>
          isActive ? 'active' : isPending ? 'pending' : ''
        }
      >
        {generateLabel(menu)}
      </NavLink>
    ),
  };
};

const generateLabel = menu => {
  if (menu.path && menu.path !== '') {
    return menu.label;
  } else {
    return <Typography.Title level={5}>{menu.label}</Typography.Title>;
  }
};

export default MenuBuilder;
