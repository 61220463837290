import {Card, Col, Divider, Row} from 'antd';
import * as PropTypes from 'prop-types';
import {selectAuth} from '../../../redux/selectors';
import {connect} from 'react-redux';
import React from 'react';
import {RQGetCampaignDashboardEnrollments} from '../../../reactQuery/apis/rqCampaignDashboardAPI';
import EnrollmentTableColumns from './EnrollmentTableColumns';
import {SearchTable} from '../../../components/common/SearchTable';

const CampaignDashboardScreen = ({auth})=> {
  //RQGetCampaignDashboardEnrollments

  const {
    data:enrollments = {},
  } = RQGetCampaignDashboardEnrollments({
    token: auth?.token,
    filter: {},
    locationId: auth?.employee?.location?.id,
  });

  return(
    <Card>
      <Divider orientation={'left'}>Dashboard</Divider>
      <Row gutter={[24]}>
        <Col span={24}>
          <SearchTable dataSource={enrollments?.yearMonths || []} data={enrollments?.yearMonths || []} columns={EnrollmentTableColumns(enrollments)}
            title={()=> 'Enrollments By Month'}
          />
        </Col>
      </Row>
    </Card>
  );
};

CampaignDashboardScreen.propTypes = {
  auth: PropTypes.any,
  menuClicked: PropTypes.bool,
  onClick: PropTypes.func,
  setMenuClicked: PropTypes.func,
};

const mapState = state => {
  const auth = selectAuth(state);
  return {auth};
};

export default connect(mapState)(CampaignDashboardScreen);
