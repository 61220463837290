
const groupBy = function(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export {groupBy};

export const getFileBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
});

export const getFileAsBinary = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsBinaryString(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
});

export const getWindowDimensions = () => {
  const {innerWidth: width, innerHeight: height} = window;
  return {
    width,
    height,
  };
};

export const BREAK_POINT = 768;

export const FORM_LAYOUT =  {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

export const hasPrivileges = (auth, privId) => {
  return auth?.privileges?.indexOf(privId) >=0;
};

export const removeNullUndefined = obj => Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
