import React from 'react';
import {Outlet} from 'react-router-dom';
import * as PropTypes from 'prop-types';
import {Layout} from 'antd';
import './ContentLayout.less';

const {Content} = Layout;

const ContentLayout = () => {
  return (
    <Content className={'main-content-container'}>
      <Outlet />
    </Content>
  );
};

ContentLayout.propTypes = {navigation: PropTypes.any};

export default ContentLayout;
