export const RQ_CALL_AVERAGE_PORTLET_API = {
  url: 'v2/portlet/call-average/<%= locationId %>/<%= fromYearMonth %>/<%= toYearMonth %>',
  method: 'GET',
  auth: true,
};

export const RQ_COVERAGE_PORTLET_API = {
  url: 'v2/portlet/coverage/<%= locationId %>/<%= fromYearMonth %>/<%= toYearMonth %>',
  method: 'GET',
  auth: true,
};

export const RQ_INVOICE_PORTLET_API = {
  url: 'v2/portlet/orderInvoices/<%= locationId %>/<%= fromYearMonth %>/<%= toYearMonth %>',
  method: 'GET',
  auth: true,
};

export const RQ_JOINT_VISIT_PORTLET_API = {
  url: 'v2/portlet/joint-calls/<%= locationId %>/<%= fromYearMonth %>/<%= toYearMonth %>',
  method: 'GET',
  auth: true,
};

export const RQ_FIELD_DAYS_PORTLET_API = {
  url: 'v2/portlet/field-days/<%= locationId %>/<%= fromYearMonth %>/<%= toYearMonth %>',
  method: 'GET',
  auth: true,
};

export const RQ_EXECUTION_EFFECTIVINESS_PORTLET_API = {
  url: 'v2/call-report/execution-effectiveness/<%= locationId %>/<%= toYearMonth %>',
  method: 'GET',
  auth: true,
};
