import './GlobalColors.css';

const cssValue = property => {
  return getComputedStyle(document.documentElement)
    .getPropertyValue(property);
};

// other colors
export const WHITE_COLOR = cssValue('--WHITE_COLOR');
export const BLACK_COLOR = cssValue('--BLACK_COLOR');

// primary
export const PRIMARY_COLOR = cssValue('--PRIMARY_COLOR');
export const PRIMARY_50 = cssValue('--PRIMARY_50');
export const PRIMARY_100 = cssValue('--PRIMARY_100');
export const PRIMARY_200 = cssValue('--PRIMARY_200');
export const PRIMARY_300 = cssValue('--PRIMARY_300');
export const PRIMARY_400 = cssValue('--PRIMARY_400');
export const PRIMARY_500 = cssValue('--PRIMARY_500');
export const PRIMARY_600 = cssValue('--PRIMARY_600');
export const PRIMARY_700 = cssValue('--PRIMARY_700');
export const PRIMARY_800 = cssValue('--PRIMARY_800');
export const PRIMARY_900 = cssValue('--PRIMARY_900');

// secondary
export const SECONDARY_COLOR = cssValue('--SECONDARY_COLOR');
export const SECONDARY_50 = cssValue('--SECONDARY_50');
export const SECONDARY_100 = cssValue('--SECONDARY_100');
export const SECONDARY_200 = cssValue('--SECONDARY_200');
export const SECONDARY_300 = cssValue('--SECONDARY_300');
export const SECONDARY_400 = cssValue('--SECONDARY_400');
export const SECONDARY_500 = cssValue('--SECONDARY_500');
export const SECONDARY_600 = cssValue('--SECONDARY_600');
export const SECONDARY_700 = cssValue('--SECONDARY_700');
export const SECONDARY_800 = cssValue('--SECONDARY_800');
export const SECONDARY_900 = cssValue('--SECONDARY_900');

export const ORANGE_50 = cssValue('--ORANGE_50');
export const ORANGE_100 = cssValue('--ORANGE_100');
export const ORANGE_200 = cssValue('--ORANGE_200');
export const ORANGE_300 = cssValue('--ORANGE_300');
export const ORANGE_400 = cssValue('--ORANGE_400');
export const ORANGE_700 = cssValue('--ORANGE_700');

export const GREEN_COLOR = cssValue('--GREEN_COLOR');
export const GREEN_50 = cssValue('--GREEN_50');
export const GREEN_200 = cssValue('--GREEN_200');
export const GREEN_300 = cssValue('--GREEN_300');
export const GREEN_400 = cssValue('--GREEN_400');
export const GREEN_500 = cssValue('--GREEN_500');
export const GREEN_600 = cssValue('--GREEN_600');
export const GREEN_700 = cssValue('--GREEN_700');
export const GREEN_900 = cssValue('--GREEN_900');

export const RED_COLOR = cssValue('--RED_COLOR');
export const RED_50 = cssValue('--RED_50');
export const RED_100 = cssValue('--RED_100');
export const RED_200 = cssValue('--RED_200');
export const RED_300 = cssValue('--RED_300');
export const RED_500 = cssValue('--RED_500');
export const RED_600 = cssValue('--RED_600');
export const RED_700 = cssValue('--RED_700');

export const YELLOW_COLOR = cssValue('--YELLOW_COLOR');
export const YELLOW_50 = cssValue('--SECONDARY_900');
export const YELLOW_100 = cssValue('--YELLOW_100');
export const YELLOW_200 = cssValue('--YELLOW_200');
export const YELLOW_300 = cssValue('--YELLOW_300');
export const YELLOW_500 = cssValue('--YELLOW_500');
export const YELLOW_600 = cssValue('--YELLOW_600');

export const GRAY_COLOR = cssValue('--GRAY_COLOR');
export const GRAY_50 = cssValue('--GRAY_50');
export const GRAY_100 = cssValue('--GRAY_100');
export const GRAY_200 = cssValue('--GRAY_200');
export const GRAY_300 = cssValue('--GRAY_300');
export const GRAY_400 = cssValue('--GRAY_400');
export const GRAY_500 = cssValue('--GRAY_500');
