import {Link} from 'react-router-dom';

const EnrollmentTableColumns = enrollments => {

  const statusCols = enrollments?.statuses?.map( status => {
    return {
      dataIndex: 'status',
      key: 'status',
      title: status,
      render: (_, record) => {
        const key = `(${record.value}, ${status})`;
        if (enrollments.data[key])
        {return(
          <Link to={`/home/report/enrollments/${record.value}/${status}`}>
            {enrollments.data[key]}</Link>
        );}
        else {return '';}
      },
    };
  },
  );

  return [
    {
      dataIndex:'displayName',
      key: 'monthYear',
      title: 'Month',
    },
    ...statusCols || [],
  ];
};

export default EnrollmentTableColumns;
