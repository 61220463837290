import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {authLoader} from '../../loader';
import {ROUTE_POD_REPORT} from '../../RoutePathConstant';

const PODReport = lazy(() =>
  import('../../../screens/PODReport')
    .then(module => ({default: module.PODReport})));

const PODReportNavigation = [
  {
    path:  ROUTE_POD_REPORT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <PODReport />
      </Suspense>
    ),
  },
];

export default PODReportNavigation;
