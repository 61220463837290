import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
const monthNames = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const toYearMonth = date => dayjs(date).format('YYYYMM');

const toYearMonthDate = date => dayjs(date).format('YYYYMMDD');
const toDisplayYearMonth = date => dayjs(date).format('MMM-YYYY');

const toDisplayYYMMM = date => dayjs(date).format('MMM-YY');

const toShortDate = yyyyMmDd => {
  dayjs.extend(customParseFormat);
  return dayjs(`${yyyyMmDd}`, 'YYYYMMDD').format('DD-MMM'); };

const toFullDate = yyyyMmDd => {
  if (yyyyMmDd === null || yyyyMmDd === undefined) {return  '';}
  dayjs.extend(customParseFormat);
  return dayjs(`${yyyyMmDd}`, 'YYYYMMDD').format('DD-MMM-YYYY'); };
const toMonth = yyyyMmDd => {
  dayjs.extend(customParseFormat);
  return dayjs(`${yyyyMmDd}`, 'YYYYMMDD').format('MMM');
};

const toDateTime = date => {
/*  dayjs.extend(customParseFormat);
  dayjs.extend(utc);
  let hh = parseInt(dayjs.utc(date).format('HH'));
  if (hh < 9) {
    let newDateTime = dayjs.utc(date).add(5, 'hour').add(30, 'minute');
    return dayjs.utc(newDateTime).format('HH:mm');
  }
  //let newDateTime = dayjs(date).add(5, 'hour').add(30, 'minute');
  return dayjs.utc(date).format('HH:mm');*/
  dayjs.extend(utc);
  return dayjs(date).utc('z').local().format('H:mm');
};

const toMonthYear = yyyyMm => {
  let year = `${yyyyMm}`.substring(2, 4);
  let month = `${yyyyMm}`.substring(4);
  return monthNames[parseInt(month)] + '-' +  year;
};

export {toYearMonth, toShortDate, toFullDate, toMonth, toDisplayYearMonth, toMonthYear, toDisplayYYMMM, toYearMonthDate, toDateTime};
