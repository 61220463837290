export const RQ_MARKETING_REPORT_SEARCH_API = {
  url: 'v2/marketing-report/search?',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_MARKETING_DETAILS_REPORT_SEARCH_API = {
  url: 'v2/marketing-report/details/search?',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_MARKETING_DETAILS_APPROVAL_SEARCH_API = {
  url: 'v2/marketing-report/details-approval/search?',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_MARKETING_DUMP_REPORT_SEARCH_API = {
  url: 'v2/marketing-report/dump/search?',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_CUSTOMER_TAGGING_REPORT_SEARCH_API = {
  url: 'v2/customer/retailer-tagging/search?',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_HCP_POTENTIAL_SEARCH_API = {
  url: 'v2/efforts/hcp-potential/search?',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_CALL_STOCK_SEARCH_API = {
  url: 'v2/efforts/search-visit-stock/search?',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_CALL_POB_SEARCH_API = {
  url: 'v2/efforts/search-visit-pob/search?',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_MISSED_CALL_SEARCH_API = {
  url: 'v2/call-report/missedCalls-web/search?',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_JOINT_WORK_DUMP_SEARCH_API = {
  url: 'v2/efforts/jwf-dump/search?',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_ORDER_REPORT_SEARCH_API = {
  url: 'v2/order-report/web/search?',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_ORDER_RCPA_REPORT_SEARCH_API = {
  url: 'v2/order-report/web/rcpa?',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_POD_REPORT_SEARCH_API = {
  url: 'v2/invoice/dump?',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_GET_MARKETING_ACTIVITY_LIST_API = {
  url: 'v2/marketing-activity/all/active',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_APPROVE_MARKETING_ACTIVITY_API = {
  url: 'v2/marketing-activity-attendee/approve/<%= id %>/<%= activityId %>',
  method: 'PUT',
  auth: true,
};

export const RQ_REJECT_MARKETING_ACTIVITY_API = {
  url: 'v2/marketing-activity-attendee/reject/<%= id %>/<%= activityId %>',
  method: 'PUT',
  auth: true,
};
