import React from 'react';
import {Col, Row} from 'antd';
import {WelcomeHeader} from './WelcomeHeader';
import './HeaderLayout.less';
import {LogoutButton} from '../SideMenu/LogoutButton';

import PropTypes from 'prop-types';
import ContextSelector from './ContextSelector/ContextSelector';

const HeaderLayout = ({menuClicked}) => {

  return (
    <Row direction={'horizontal'}
      gutter={[8]}
      className={'main-header-container'}>
      <Col span={10}>
        <WelcomeHeader />
      </Col>
      <Col span={4} offset={7}>
        <ContextSelector />
      </Col>
      <Col span={3}>
        <LogoutButton />
      </Col>
    </Row>
  );
};

HeaderLayout.propTypes = {
  menuClicked: PropTypes.func,
};

export default HeaderLayout;
