import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {authLoader} from '../../loader';
import {ROUTE_REVIEW_INVOICE, ROUTE_REVIEW_INVOICES} from '../../RoutePathConstant';

const MainReviewScreen = lazy(() =>
  import('../../../screens/ReviewInvoices')
    .then(module => ({default: module.ReviewInvoices})));

const ReviewInvoiceScreen = lazy(() =>
  import('../../../screens/ReviewInvoices')
    .then(module => ({default: module.ReviewInvoice})));
const ReviewInvoiceNavigation = [
  {
    path:  ROUTE_REVIEW_INVOICES,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <MainReviewScreen />
      </Suspense>
    ),
  },
  {
    path:  ROUTE_REVIEW_INVOICE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ReviewInvoiceScreen />
      </Suspense>
    ),
  },
];

export default ReviewInvoiceNavigation;
