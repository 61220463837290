import PropTypes from 'prop-types';
import {selectAuth} from '../../../redux/selectors';
import {connect} from 'react-redux';
import React from 'react';
import {Table} from 'antd';

import styles from './SearchTable.module.less';
import {isEmpty} from 'lodash';

const SearchTable = ({data, columns, dataSource, title=''}) => {

  dataSource?.forEach((item, index) => {
    item.key = index + 1;
  });

  if(columns!==undefined){
    for(let i=0; i<columns.length; i++){
      delete columns[i]?.fixed;
    }
  }

  return (

    <Table
      title={title}
      className={styles.table}
      columns={columns}
      dataSource={dataSource}
      size={'large'}
      bordered
      defaultPageSize={1}
      pagination={{defaultPageSize: 25}}
      loading={!data}
      scroll={{
        x: `${!isEmpty(columns) ? (columns.length * 100)+'px' : '100%'}`,
        y: '70vh',
      }}
    />
  );
};

SearchTable.propTypes = {
  data: PropTypes.array,
  dataSource: PropTypes.array,
  columns: PropTypes.array,
  title: PropTypes.string,
};

const mapState = state => {
  const auth = selectAuth(state);
  return {auth};
};

export default connect(mapState)(SearchTable);
