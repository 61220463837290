import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {authLoader} from '../../loader';
import {ROUTE_UPLOAD_LOGS} from '../../RoutePathConstant';

const UploadLogs = lazy(() =>
  import('../../../screens/Uploads')
    .then(module => ({default: module.UploadLogs})));

const UploadLogsNavigation = [
  {
    path:  ROUTE_UPLOAD_LOGS,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <UploadLogs />
      </Suspense>
    ),
  },
];

export default UploadLogsNavigation;
